.stage-wrapper {
  min-height: calc(100vh - 276px);
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .stage-label {
    color: var(--primary-gray);
    font-size: 12px;
  }

  .stage-title {
    color: var(--primary-dark-gray);
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .approved-label {
      font-size: 12px;
      color: #27ae60;
      padding: 4px 10px;
      background-color: #e8fff3;
      margin-left: 18px;
      font-weight: 400;
    }
  }

  .stage-actions {
    margin-top: 32px;
    display: flex;
    align-items: center;

    button {
      width: 114px;
      height: 32px;
      font-size: 12px;
      font-family: "Inter", sans-serif;
      border-radius: 2px;
    }
    .approve-btn {
      width: 114px;
      box-sizing: border-box;
      padding: 8px 0;
      margin-right: 8px;
      background-color: var(--primary-blue);
      color: var(--white);
    }
    .draft-btn {
      background-color: #f2f2f2;
      color: var(--primary-soft-gray);
      margin-left: 32px;
    }
    .notifications-btn {
      width: 32px;
      height: 32px;
      background-color: #eef6ff;
      border-radius: 2px;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .stage-warning:hover {
      .warning-text {
        display: block;
      }
    }
    .stage-warning {
      height: 32px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      position: relative;
      .warning-text {
        width: 250px;
        padding: 10px;
        border-radius: 4px;
        font-size: 12px;
        position: absolute;
        bottom: 45px;
        right: -112px;
        background-color: var(--white);
        box-sizing: border-box;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
        display: none;
        z-index: 100;
        &::after {
          content: "";
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-top: 12px solid var(--white);
          position: absolute;
          bottom: -11px;
          left: calc(50% - 12px);
        }
        span {
          text-transform: capitalize;
          font-weight: 600;
          display: block;
        }
      }
      img {
        height: 80%;
        filter: invert(35%) sepia(54%) saturate(5646%) hue-rotate(352deg)
          brightness(97%) contrast(97%);
      }
    }
  }
}

.dynamic-fields-box {
  margin: 70px 0 70px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: var(--primary-light-gray);
    top: -32px;
    left: 0;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: var(--primary-light-gray);
    bottom: -32px;
    left: 0;
  }

  .form-fields {
    display: flex;
    .section-title {
      font-size: 12px;
      color: var(--primary-soft-gray);
      margin-bottom: 9px;
      text-transform: capitalize;
    }
    .form-control {
      width: calc(20% - 16px);
      display: block;
      margin-bottom: 15px;

      input {
        width: 100%;
      }

      .mat-mdc-form-field {
        width: 100%;
        input {
          margin-top: 4px;
          font-size: 14px;
        }
      }

      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;

    button {
      font-size: 12px;
      border-radius: 0;
      height: 32px;
    }

    .save-btn {
      background-color: var(--primary-blue);
      width: 109px;
      color: var(--white);
      font-family: "Inter", sans-serif;
    }
  }
}

.page-wrapper {
  padding: 32px;
  font-family: "Inter", sans-serif;
  height: auto;
}

.order-page-wrapper {
  min-height: calc(100vh - 45px);
  box-sizing: border-box;
}

.title-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 23px;

  @media all and (max-width: 1000px) {
    flex-flow: row wrap;
  }

  .title {
    font-size: 30px;
    font-weight: 500;
    display: flex;

    @media all and (max-width: 1000px) {
      flex-flow: row wrap;
    }

    @media all and (max-width: 650px) {
      font-size: 20px;
    }

    .title-count {
      margin-left: 16px;
      padding: 6px 16px;
      background-color: #f2f8ff;
      color: var(--primary-blue);
      border-radius: 4px;
      position: relative;
      top: -2px;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.filter-box {
  display: flex;

  @media all and (max-width: 1000px) {
    flex-flow: row wrap;
  }

  .active-filter {
    background-color: var(--primary-dark-blue) !important;
    color: var(--white);
  }

  .filter-btn {
    height: 32px;
    border-radius: 16px;
    padding: 7px 16px;
    margin-right: 8px;
    background-color: #f7f7f7;
    box-sizing: border-box;
    font-size: 12px;
    cursor: pointer;

    @media all and (max-width: 800px) {
      margin-bottom: 15px;
    }

    .divider {
      position: relative;
      top: -25%;
      margin: 0 8px 0 5px;
    }
  }
}

.search-box {
  padding: 10px;
  background-color: #fafafa;
  border-radius: 2px;
  display: flex;
  margin-bottom: 24px;

  @media all and (max-width: 1000px) {
    flex-flow: row wrap;
  }

  .form-control {
    width: calc(100% - 112px);
    box-sizing: border-box;
    margin-right: 16px;

    @media all and (max-width: 600px) {
      width: 100%;
      margin: 0 0 15px 0;
    }

    .icon {
      border-radius: 4px 0 0 4px;
    }
    input {
      height: 32px;
      border-radius: 0 4px 4px 0;
      border-left: none;
    }
  }

  .display-options {
    display: flex;

    .active-disp-btn {
      color: var(--primary-blue);
      border: 1px solid var(--primary-blue) !important;
    }

    .disp-btn {
      display: flex;
      align-items: center;
      cursor: pointer;
      background-color: var(--white);
      box-sizing: border-box;

      &:hover {
        background-color: #f2f8ff;
      }
    }

    .disp-btn[aria-expanded="true"] {
      mat-icon {
        transform: rotate(180deg);
      }
    }

    .display-all {
      width: 104px;
      box-sizing: border-box;
      border: 1px solid var(--primary-light-gray);
      border-right: none;
      border-radius: 4px 0 0 4px;
      justify-content: center;
    }

    .display-filters {
      width: 104px;
      height: 32px;
      border: 1px solid var(--primary-light-gray);
      border-radius: 0 4px 4px 0;
      justify-content: space-evenly;
      position: relative;

      .btn-title {
        display: flex;
        align-items: center;

        mat-icon {
          position: relative;
          right: -5px;
        }
      }

      .filter-options {
        position: absolute;
        top: 35px;
        background-color: var(--white);
        width: 170px;
        z-index: 100;
        padding: 5px 5px 5px 0;
        border-radius: 4px;

        ::ng-deep {
          .mdc-form-field > label {
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .add-btn {
    width: 180px;
    height: 32px;
    background-color: var(--primary-blue);
    color: var(--white) !important;
  }
}

.f-option {
  height: 36px;
  margin-right: 15px;
  label {
    text-transform: capitalize;
  }
}
.table-box {
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #e2ecf9;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #aec3dc;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #7c9bc0;
  }
}

.data-table {
  min-width: 1580px !important;
  .sortable-header {
    cursor: pointer;
  }
  th {
    font-size: 12px;
    color: var(--primary-soft-gray);
    font-family: "Inter", sans-serif;
    user-select: none;
    text-transform: uppercase;

    .table-header-wrapper {
      display: flex;
      align-items: center;

      .sort-controls {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-left: 10px;

        img {
          &:not(:last-of-type) {
            margin-bottom: 3px;
          }
        }
      }
    }
  }

  td {
    font-family: "Inter", sans-serif;
    color: var(--primary-dark-blue);
  }
}

.orders-table {
  min-width: 1730px !important;
  .mat-column-poNumber,
  .mat-column-orderNumber {
    width: 120px;
    flex: 0 0 120px;
  }

  .mat-column-createdOn,
  .mat-column-lastModifiedOn {
    width: 145px;
    flex: 0 0 145px;
  }

  .mat-column-stage {
    width: 160px;
    flex: 0 0 160px;
  }

  .po-number {
    a {
      color: var(--primary-dark-blue);
      text-decoration: none;
    }
    .num-box {
      position: relative;
      .new-label {
        position: absolute;
        top: -11px;
        right: 5px;
        font-size: 8px;
        text-transform: uppercase;
        color: var(--white);
        background-color: var(--primary-blue);
        border-radius: 2px;
        padding: 2px 4px;
        height: 14px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
      }
    }
  }

  .order-number {
    a {
      color: var(--primary-dark-blue);
      text-decoration: none;
    }
  }

  .consignee-info,
  .date-info {
    color: var(--primary-soft-gray);
  }

  .order-stage {
    .stage-title {
      font-size: 12px;
    }

    .stage-diagram {
      width: 105px;
      height: 20px;
      background-color: #f2f2f2;
      border-radius: 2px;
      padding: 0 1px;
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;

      .diagram-cell {
        width: 20%;
        height: 16px;
        background-color: var(--white);
        margin: 2px 1px;
        box-sizing: border-box;
      }

      .completed {
        background-color: #62ce96 !important;
      }

      .one {
        background-color: #c8e0fe;
      }

      .two {
        background-color: #a9d0ff;
      }

      .three {
        background-color: #85bcff;
      }

      .four {
        background-color: #6cafff;
      }

      .five {
        background-color: #5ca6ff;
      }
    }

    .done-mark {
      display: inline-flex;
      padding-left: 5px;
      position: absolute;
      top: 50%;
      box-sizing: border-box;

      mat-icon {
        color: #219653;
        font-size: 16.25px;
      }
    }
  }
}

.closed-label {
  background-color: #e4fdf1;

  .status-mark {
    background-color: var(--primary-green);
  }

  .status-text {
    color: var(--primary-green);
  }
}

.open-label {
  background-color: #eaf6ff;

  .status-mark {
    background-color: var(--primary-soft-blue);
  }

  .status-text {
    color: var(--primary-soft-blue);
  }
}

.canceled-label {
  background-color: #fde7e4;

  .status-mark {
    background-color: var(--primary-red);
  }

  .status-text {
    color: var(--primary-red);
  }
}

.status-label {
  height: 25px;
  padding: 4px 14px;
  border-radius: 14px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;

  .status-mark {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 5px;
  }

  .status-text {
    text-transform: capitalize;
    position: relative;
    top: 0.5px;
  }
}

.field-error {
  display: block;
  font-size: 11px;
  color: #ff0000;
  height: 24px;
}

.no-data-row {
  .no-data-cell {
    padding: 20px;
    text-align: center;
    font-size: 16px;
    color: var(--primary-dark-blue);
  }
}

.no-click {
  pointer-events: none;
  cursor: default;
}

.not-found-wrapper {
  height: calc(100vh - 45px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;

  .not-found-container {
    padding: 24px;
    text-align: center;
    color: var(--primary-dark-blue);

    .code-box {
      font-size: 200px;
      font-weight: 900;
      line-height: 200px;
      color: var(--primary-dark-blue);
      margin-bottom: 30px;
    }

    .not-found-box {
      font-size: 20px;
      line-height: 20px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .page-hint {
      font-size: 16px;
      line-height: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }
}

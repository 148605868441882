.login {
  display: flex;
  align-items: stretch;
  height: 100vh;
  font-family: "Inter", sans-serif;

  &-form {
    width: 50%;
    background: url("/assets/img/login-form-background.png");

    @media all and (min-width: 1601px) {
      width: calc(100% - 960px);
    }

    @media all and (max-width: 1000px) {
      width: 100%;
    }

    .logo-box {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      img {
        width: 182px;
        position: relative;
        bottom: 70px;

        @media all and (max-width: 1600px) {
          bottom: 0;
        }
      }
    }

    .form-box {
      display: flex;
      justify-content: center;
      align-items: center;

      .code-hint {
        color: #4f4f4f;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 8px;
      }

      .card {
        width: 334px;
        box-sizing: border-box;
        background-color: var(--white);
        padding: 24px 40px 38px;
        border-radius: 4px;

        @media all and (max-width: 1600px) {
          position: relative;
          top: 30px;
        }

        h2 {
          font-size: 30px;
          font-weight: 500;
          font-family: "Inter", sans-serif;
          margin: 12px 0 32px;
        }

        .form {
          .form-control {
            input {
              border-left: none;
              border-radius: 0;
            }
          }
          .field-error {
            display: block;
            font-size: 11px;
            color: #ff0000;
            height: 24px;
          }
        }

        .remember-me {
          margin: 0 0 20px;

          mat-checkbox {
            position: relative;
            left: -10px;
          }

          ::ng-deep {
            .mdc-checkbox__background {
              width: 16px;
              height: 16px;
              border-radius: 4px;
              border-width: 1px;
            }

            .mat-mdc-checkbox
              .mdc-checkbox
              .mdc-checkbox__native-control:enabled:checked
              ~ .mdc-checkbox__background,
            .mat-mdc-checkbox
              .mdc-checkbox
              .mdc-checkbox__native-control:enabled:indeterminate
              ~ .mdc-checkbox__background,
            .mat-mdc-checkbox
              .mdc-checkbox
              .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
              ~ .mdc-checkbox__background {
              border-color: var(--primary-blue);
              background-color: var(--primary-blue);
            }
          }
        }

        .login-btn {
          background-color: var(--primary-blue);
          color: var(--white);
          font-family: "Inter", sans-serif;
          width: 100%;
          margin-bottom: 24px;
          border-radius: 2px;
        }

        .forgot-pass {
          color: #4f4f4f;
          text-decoration: none;
          font-size: 12px;
          display: block;
          text-align: center;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .terms-privacy-box {
      display: flex;
      align-items: flex-end;

      .best-companies,
      .third {
        width: calc((100% - 334px) / 2);
        display: flex;
        justify-content: center;

        @media all and (max-width: 530px) {
          width: calc((100% - 250px) / 2);
        }

        img {
          width: 93px;
          height: 37px;
        }
      }

      .best-companies {
        @media all and (max-width: 430px) {
          width: calc(100% - 250px);
          justify-content: flex-start;
        }
      }

      .third {
        @media all and (max-width: 430px) {
          display: none;
        }
      }

      .terms {
        width: 334px;
        display: flex;
        justify-content: center;

        @media all and (max-width: 530px) {
          width: 250px;
        }

        a {
          font-size: 12px;
          text-decoration: none;
          color: #4f4f4f;
          position: relative;
          bottom: 8px;

          &:hover {
            text-decoration: underline;
          }

          &:first-of-type {
            margin-right: 16px;
          }

          &:last-of-type {
            margin-left: 16px;
          }
        }
      }
    }
  }

  &-slogan {
    width: 50%;
    background: url("/assets/img/login-slogan-background.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #4f4f4f;

    @media all and (min-width: 1601px) {
      width: 960px;
    }

    @media all and (max-width: 1000px) {
      display: none;
    }

    .header {
      width: 601px;
      margin: 110px auto 64px;
      text-align: center;
      font-size: 48px;
      line-height: 57px;
      font-family: "Clarendon BT", serif;

      @media all and (max-width: 1200px) {
        width: 400px;
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 45px;
      }
    }

    .subheader {
      width: 410px;
      margin: auto;
      text-align: center;
      font-size: 18px;
      font-family: "Inter", sans-serif;

      @media all and (max-width: 1200px) {
        font-size: 16px;
      }
    }
  }
}

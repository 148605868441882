.modal-el {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  z-index: 1000;

  .modal-info-box {
    background-color: var(--white);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 32px;
    box-sizing: border-box;
  }

  .modal-info-box-alt {
    background-color: var(--white);
    height: 100vh;
  }
}

.side-modal {
  justify-content: flex-end;

  .close-side-modal {
    position: absolute;
    top: 20px;
    right: 380px;
    color: var(--white);
    z-index: 1002;
    cursor: pointer;
    font-size: 28px;
  }

  .side-info {
    width: 364px;
    overflow-y: auto;
    position: relative;

    .side-info-head {
      background-image: url("/assets/img/background.svg");
      background-size: cover;
      background-color: var(--primary-dark-blue);
      color: var(--white);
      padding: 30px 24px 24px;

      .title-close {
        display: flex;
        justify-content: space-between;
        margin-bottom: 26px;

        span {
          font-size: 24px;
        }
      }

      .main-info {
        .upper {
          font-weight: 500;
          font-size: 16px;
          margin-bottom: 7px;
        }

        .middle {
          font-size: 14px;
          color: var(--primary-light-gray);
          margin-bottom: 14px;
        }

        .lower {
          font-size: 12px;
          color: var(--primary-soft-gray);
        }
      }
    }

    .edit-form {
      padding: 30px 24px 24px;

      ::ng-deep {
        .mat-mdc-select-trigger {
          height: 30px;
        }
        .mat-mdc-text-field-wrapper {
          height: 32px !important;
        }

        .mat-mdc-form-field-flex {
          height: 32px !important;
        }

        .mdc-checkbox__background {
          width: 20px !important;
          height: 20px !important;
        }

        .mat-mdc-checkbox label {
          top: 4px;
        }
      }

      .double-controls {
        display: flex;

        .form-control:first-of-type {
          margin-right: 16px;
        }
      }

      .form-control {
        display: block;
        margin-bottom: 16px;

        mat-checkbox {
          position: relative;
          left: -10px;
        }
      }

      label {
        font-size: 12px;
      }
    }

    .side-actions {
      background-color: #f9f9f9;
      padding: 20px 0;
      display: flex;
      justify-content: center;
      box-sizing: border-box;

      button {
        width: 146px;
        height: 32px;
      }
    }

    .customer-staff {
      padding: 0 24px;
      margin-bottom: 40px;
      .staff-title {
        font-size: 18px;
        color: var(--primary-dark-gray);
        margin-bottom: 16px;
      }

      .user {
        font-size: 14px;
        margin-bottom: 8px;
        padding: 16px;
        position: relative;
        border: 1px solid var(--primary-light-gray);

        .u-actions {
          position: absolute;
          top: -10px;
          right: -3px;
          mat-icon {
            color: var(--primary-soft-gray);
          }
        }

        .u-name-role {
          color: var(--primary-dark-blue);
          .u-name {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            padding-right: 15px;
          }
          span {
            color: var(--primary-soft-gray);
          }
        }

        .u-mob-phone {
          color: var(--primary-dark-blue);
        }

        .u-email {
          color: var(--primary-blue);
        }
      }
    }

    .line {
      height: 1px;
      background-color: var(--primary-light-gray);
      margin: 0 24px 12px;
    }
  }
}

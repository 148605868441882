.page-wrapper {
  .search-box {
    .form-control {
      @media all and (max-width: 1000px) {
        width: 100%;
        margin: 0 0 15px 0;
      }
    }
  }
  .customer-box {
    display: flex;
    margin-bottom: 20px;

    @media all and (max-width: 710px) {
      flex-flow: row wrap;
    }

    .address-info {
      background-color: rgba(#4c9cfd, 0.07);
      padding: 16px 30px 16px 24px;
      box-sizing: border-box;
      min-width: 300px;
      width: 359px;
      height: 158px;

      @media all and (max-width: 710px) {
        width: 100%;
      }

      .country,
      .address {
        font-size: 12px;
        color: var(--primary-soft-gray);
      }

      .country-name,
      .address-data {
        font-size: 14px;
        color: var(--primary-dark-blue);
      }

      .address {
        margin-top: 13px;
      }
    }

    .peoples-info {
      display: flex;
      flex-wrap: wrap;
      margin-left: 20px;
      position: relative;
      top: -16px;

      @media all and (max-width: 710px) {
        width: 100%;
        margin: 15px 0 0;
      }

      .user {
        font-size: 14px;
        padding: 16px;
        width: 316px;
        height: 71px;
        position: relative;
        border: 1px solid var(--primary-light-gray);
        margin: 16px 20px 0 0;
        box-sizing: border-box;

        @media all and (max-width: 710px) {
          width: 100%;
          margin: 15px 0 0;
        }

        .u-actions {
          position: absolute;
          top: -2px;
          right: 0;
          mat-icon {
            color: var(--primary-soft-gray);
          }
        }

        .u-name {
          color: var(--primary-dark-blue);
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          padding-right: 15px;
        }

        .u-info {
          display: flex;
          justify-content: space-between;
          .u-role {
            color: var(--primary-soft-gray);
            margin-right: 10px;
          }

          .u-phone {
            color: var(--primary-blue);
          }
        }
      }
    }
  }
  .table-box {
    .customers-table,
    .cold-storage-table {
      min-width: 100% !important;
      width: 100%;
      .mat-column-users,
      .mat-column-email {
        width: 200px;
        flex: 0 0 200px;
      }
      .mat-column-created-date,
      .mat-column-latest-update {
        width: 115px;
        flex: 0 0 115px;
      }
      .mat-column-orders {
        width: 80px;
        flex: 0 0 80px;
      }
      .customer-data-row {
        cursor: pointer;
      }

      td {
        color: var(--primary-dark-gray);
      }

      .temperature {
        font-weight: 500;
        color: var(--primary-soft-blue);

        span {
          color: var(--primary-blue);
        }
      }

      .country {
        color: var(--primary-soft-gray);
      }

      .company-info,
      .phone,
      .country-info {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .email {
        text-decoration: underline;
        .email-info {
          width: 200px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      .orders {
        color: var(--primary-blue);
      }

      .users {
        position: relative;
        .first-user {
          width: 100%;
          margin-top: 5px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .extra-users {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 85px;
          cursor: pointer;
          height: 28px;
          border-radius: 4px;
          background-color: rgba(#4c9cfd, 0.07);
          color: var(--primary-blue);
          margin: 5px 0;
        }
      }
    }
  }
}

.extra-user {
  padding: 0 14px;
}

.extra-user:not(:last-of-type) {
  margin-bottom: 10px;
}

.u-info {
  display: flex;
  align-items: center;
  font-size: 14px;

  .circle {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: var(--primary-soft-gray);
    margin: 0 11px;
  }

  .u-role {
    color: var(--primary-soft-gray);
  }
}

.u-phone {
  display: flex;
  align-items: center;
  font-size: 14px;

  img {
    margin-right: 5px;
  }
}

.mdc-radio__background {
  background-color: transparent;
  &::before {
    display: none;
  }
}

.mdc-checkbox__background {
  width: 16px !important;
  height: 16px !important;
  border-radius: 4px !important;
  border-width: 1px;
}

.mat-pseudo-checkbox {
  border-radius: 4px !important;
  width: 18px !important;
  height: 18px !important;
}

.mat-pseudo-checkbox-checked {
  background: var(--primary-blue) !important;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  border-color: var(--primary-blue) !important;
  background-color: var(--primary-blue) !important;
}

.mat-mdc-radio-button .mat-radio-ripple,
.mat-mdc-checkbox .mdc-checkbox__ripple,
.mat-mdc-outlined-button .mat-mdc-button-ripple,
.mat-mdc-outlined-button .mdc-button__ripple,
.mat-mdc-outlined-button .mat-mdc-button-persistent-ripple::before,
.mat-mdc-tab-ripple,
.mdc-switch__shadow,
.mdc-switch__ripple,
.mat-mdc-icon-button .mat-mdc-focus-indicator,
.mat-mdc-button-ripple,
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  display: none;
}

.mat-mdc-tab .mdc-tab__ripple::before {
  display: none !important;
}

.mdc-switch__icons {
  display: none;
}

.mat-mdc-slide-toggle .mdc-switch {
  width: 45px !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
  height: 24px !important;
  border-radius: 12px !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle-track {
  width: 20px !important;
  height: 20px !important;
  top: 2px !important;
  left: 3px !important;
}

.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--unselected:enabled
  .mdc-switch__handle::after {
  background: #b8b8b8 !important;
}

.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled
  .mdc-switch__handle::after {
  background-color: var(--white) !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before {
  background: var(--white) !important;
  border: 1px solid #b8b8b8 !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background: #52d93c !important;
  border: 1px solid #52d93c !important;
}

.mat-mdc-menu-item {
  min-height: 32px !important;
  span.material-symbols-outlined {
    font-size: 18px;
    position: relative;
    top: 3px;
    margin-right: 10px;
  }
  span {
    font-size: 14px;
  }
}

.mat-mdc-menu-item:hover {
  background-color: #f2f8ff !important;
  span {
    color: var(--primary-blue);
  }
  .key-user-logo,
  .edit-user-logo,
  .edit-logo,
  .import-logo,
  .clear-logo {
    filter: invert(33%) sepia(25%) saturate(7181%) hue-rotate(203deg)
      brightness(98%) contrast(92%);
  }

  .delete-user-logo,
  .delete-logo,
  .rename-logo {
    filter: unset;
  }
}

.mat-mdc-text-field-wrapper {
  border: 1px solid var(--primary-light-gray) !important;
  border-radius: 4px !important;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border: none !important;
}

.mat-mdc-text-field-wrapper {
  height: 32px !important;
}

.mat-mdc-form-field-flex {
  height: 32px !important;
}

.mat-mdc-select-arrow-wrapper {
  height: 30px !important;
}

.mat-mdc-form-field-infix {
  padding: 0 !important;
}

.mat-mdc-select-value-text {
  font-size: 14px !important;
}

.select-icon-replacement {
  color: var(--primary-soft-gray);
}

.mat-mdc-select-value {
  position: relative;
  top: 3px;
}

.mat-mdc-select-value {
  padding-left: 3px !important;
}

.mat-mdc-select-arrow-wrapper {
  display: none !important;
}

.mat-mdc-checkbox label {
  font-size: 14px !important;
  position: relative !important;
  font-family: "Inter", sans-serif;
}

.mat-mdc-outlined-button {
  border-color: var(--primary-blue) !important;
}

.mdc-radio__background {
  width: 16px !important;
  height: 16px !important;
  top: 2px !important;
  left: 5px !important;
}
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: #0078d2 !important;
}

.mat-mdc-radio-button:hover {
  .mdc-radio__outer-circle {
    border-color: #0078d2 !important;
  }
}

.mdc-radio__outer-circle {
  border-width: 1px !important;
  border-color: rgba(0, 66, 105, 0.28) !important;
}

.mdc-radio__inner-circle {
  border-width: 6px !important;
  top: 2px !important;
  left: 2px !important;
  width: 6px !important;
  height: 6px !important;
  border-color: #0078d2 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--white);
}

.mat-mdc-text-field-wrapper {
  padding-left: 2px !important;
}

.mat-calendar-body-selected {
  background-color: var(--primary-blue) !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  border-color: var(--primary-blue) !important;
  background-color: transparent !important;
}

.mat-calendar-body-cell:hover {
  .mat-calendar-body-cell-content {
    background-color: #f2f8ff !important;
  }
}

.mdc-button .mdc-button__label,
.mdc-tab__text-label {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  letter-spacing: 0;
}

.mdc-text-field--focused {
  .mat-mdc-form-field-icon-suffix {
    .select-icon-replacement {
      transform: rotate(180deg);
    }
  }
}

.mat-datepicker-toggle-active {
  .mdc-icon-button {
    transform: rotate(180deg);
  }
}

.mat-mdc-autocomplete-panel {
  padding-top: 0 !important;
}

.mat-mdc-autocomplete-panel,
.mdc-menu-surface.mat-mdc-select-panel {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #e2ecf9;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #aec3dc;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #7c9bc0;
  }
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(--primary-dark-blue);
}

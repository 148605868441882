.stage-form-wrapper {
  .shipper-buyer-consignee {
    padding-bottom: 32px;
    border-bottom: 1px solid var(--primary-light-gray);
    margin-top: 32px;

    .title {
      font-size: 16px;
      font-weight: 500;
      color: var(--primary-dark-gray);
      margin-bottom: 15px;
    }

    .form {
      display: flex;
      align-items: flex-end;

      .form-control {
        display: block;
        width: calc(16.67% - 21.67px);

        &:not(:last-of-type) {
          margin-right: 26px;
        }

        label {
          font-size: 12px;
          color: var(--primary-soft-gray);
        }

        input,
        mat-form-field {
          margin-top: 14px;
        }

        mat-form-field {
          width: 100%;
        }
      }
    }

    .consignee-actions {
      justify-content: space-between !important;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;

    button {
      font-size: 12px;
      border-radius: 0;
      height: 32px;
    }

    .add-btn {
      width: 97px;
      border-color: var(--primary-blue);
      color: var(--primary-blue);
      font-family: "Inter", sans-serif;
      margin-right: 10px;
    }

    .save-btn {
      background-color: var(--primary-blue);
      width: 109px;
      color: var(--white);
      font-family: "Inter", sans-serif;
    }
  }
}

.hidden-field-placeholder {
  font-size: 10px;
  color: var(--primary-soft-gray);
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
}

.hfp-0 {
  margin-top: 0 !important;
}

.hidden-label {
  visibility: hidden;
}

.readonly-element {
  cursor: default;
  pointer-events: none !important;
}

@use "app/styles/forms";
@use "app/styles/replacements";
@use "app/styles/modals";
@use "app/styles/common";
@use "app/styles/auth";
@use "app/styles/customers";
@use "app/styles//orders";
@use "app/styles/order-stages";
@import "ngx-toastr/toastr";

@font-face {
  font-family: "Clarendon BT";
  src: url("assets/fonts/ClarendonBTRoman.woff2") format("woff2"),
    url("assets/fonts/ClarendonBTRoman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  --white: #ffffff;
  --primary-green: #34a100;
  --primary-red: #eb5757;
  --primary-blue: #0f74ef;
  --primary-dark-blue: #251d3a;
  --primary-soft-blue: #4c9cfd;
  --primary-gray: #bdbdbd;
  --primary-dark-gray: #333333;
  --primary-soft-gray: #828282;
  --primary-light-gray: #e0e0e0;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Inter", sans-serif;
}

.tag-canvas {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: var(--white);
}

.iti {
  display: block;
}

.disabled-delete {
  pointer-events: none;
  opacity: 0.5;
}

.ng2-pdf-viewer-container {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #e2ecf9;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #aec3dc;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #7c9bc0;
  }
}

.role-hint {
  padding: 8px;
  font-size: 12px;
  background: var(--white) !important;
  border-radius: 4px;
  border-left: 4px solid var(--primary-blue);
  max-width: 285px;
  box-sizing: border-box;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
  color: var(--primary-dark-blue);
}

.mdc-tooltip__surface {
  background: var(--white) !important;
  color: var(--primary-dark-blue) !important;
}

.toast-top-center {
  top: 15px;
}

.table-action-hide {
  display: none;
}

.order-items-table-tag {
  padding: 2px 4px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid var(--primary-blue);
  color: var(--primary-blue);
  background-color: #eaf6ff;
  display: inline;
}

.form-control {
  display: flex;

  .icon {
    border: 1px solid var(--primary-light-gray);
    border-right: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 43px;
    background-color: var(--white);
  }

  label {
    color: #474747;
    margin-bottom: 6px;
  }

  input {
    width: 100%;
    height: 32px;
    font-size: 14px;
    border: 1px solid var(--primary-light-gray);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 5px;
    font-family: "Inter", sans-serif;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--primary-gray);
    }
  }
}

.form-actions {
  .mat-mdc-outlined-button {
    border-color: var(--primary-blue) !important;
    background-color: transparent;
  }

  button {
    box-sizing: border-box;
  }

  .cancel-btn {
    color: var(--primary-blue) !important;
    margin-right: 24px;

    &:hover {
      background-color: transparent !important;
    }
  }

  .action-btn {
    background-color: var(--primary-blue);
    color: var(--white) !important;
  }
}

button {
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.outline-btn {
  color: var(--primary-blue) !important;
  margin-right: 24px;

  &:hover {
    background-color: transparent !important;
  }
}

input:read-only {
  cursor: default;
}
input {
  border: 1px solid var(--primary-light-gray);
}

textarea {
  border: 1px solid var(--primary-light-gray);
  font-family: "Inter", sans-serif;
  resize: vertical;
  min-height: 50px;
  max-height: 300px;
  font-size: 14px;

  &::placeholder {
    color: var(--primary-gray);
  }
}

textarea:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.mat-select-search-input {
  background-color: var(--white) !important;
}
